var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        version: "1.1",
        width: "24",
        height: "24",
        viewBox: "0 0 " + _vm.size + " " + _vm.size,
      },
    },
    [
      _c("path", { attrs: { id: _vm.svgId, d: _vm.svgItem[_vm.name].path } }),
      _vm.svgItem[_vm.name].path2
        ? _c("path", {
            attrs: { id: _vm.svgId, d: _vm.svgItem[_vm.name].path2 },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }