var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grid grid-cols-12 gap-6" }, [
    _c(
      "div",
      { staticClass: "col-span-12 flex flex-col gap-6" },
      [
        _c("vs-input", {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|max:100",
              expression: "'required|max:100'",
            },
          ],
          staticClass: "w-full",
          attrs: { label: _vm.$t("fields.title"), name: "title" },
          model: {
            value: _vm.chatroomData.roomName,
            callback: function ($$v) {
              _vm.$set(_vm.chatroomData, "roomName", $$v)
            },
            expression: "chatroomData.roomName",
          },
        }),
        _c("vs-input", {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: "max:300",
              expression: "'max:300'",
            },
          ],
          staticClass: "w-full",
          attrs: { label: _vm.$t("description"), name: "description" },
          model: {
            value: _vm.chatroomData.description,
            callback: function ($$v) {
              _vm.$set(_vm.chatroomData, "description", $$v)
            },
            expression: "chatroomData.description",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-span-12" },
      [
        _c("user-suggestion", {
          ref: "user-suggestion",
          on: { "selected-user": _vm.addUser },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-span-12" },
      [
        _c(
          "vs-table",
          {
            attrs: { data: _vm.chatroomData.users },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var data = ref.data
                  return _vm._l(data, function (user) {
                    return _c(
                      "vs-tr",
                      {
                        key: user.id,
                        attrs: { state: _vm.userRowColor(user) },
                      },
                      [
                        _c("vs-td", [
                          _vm._v(" " + _vm._s(user.username) + " "),
                        ]),
                        _c(
                          "vs-td",
                          [
                            _c("feather-icon", {
                              attrs: {
                                icon: _vm.removeUserIcon(user),
                                svgClasses:
                                  "h-5 w-5 mb-1 mr-3 stroke-current " +
                                  _vm.removeUserIconStyle(user),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.removeUser(user)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  })
                },
              },
            ]),
          },
          [
            _c(
              "template",
              { slot: "thead" },
              [
                _c("vs-th", { attrs: { width: "90%" } }, [_vm._v(" Nome ")]),
                _c("vs-th", { attrs: { width: "10%" } }, [_vm._v(" Ação ")]),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-span-12 flex gap-4 items-center justify-end" },
      [
        _c(
          "vs-button",
          { attrs: { type: "border" }, on: { click: _vm.cancel } },
          [_vm._v("Cancelar")]
        ),
        _c(
          "vs-button",
          { attrs: { disabled: _vm.invalidForm }, on: { click: _vm.store } },
          [_vm._v(_vm._s(_vm.storeButtonText))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }