<template>
  <div class="grid grid-cols-12 gap-6">

    <div class="col-span-12 flex flex-col gap-6">
      <vs-input
        class="w-full"
        :label="$t('fields.title')"
        v-model="chatroomData.roomName"
        name="title"
        v-validate="'required|max:100'"
      />
      <vs-input
        class="w-full"
        :label="$t('description')"
        v-model="chatroomData.description"
        name="description"
        v-validate="'max:300'"
      />
    </div>

    <div class="col-span-12">
      <user-suggestion
        @selected-user="addUser"
        ref="user-suggestion"
      />
    </div>

    <div class="col-span-12">
      <vs-table
        :data="chatroomData.users"
      >
        <template slot="thead">
          <vs-th width="90%">
            Nome
          </vs-th>
          <vs-th width="10%">
            Ação
          </vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr
            :key="user.id"
            v-for="user in data"
            :state="userRowColor(user)"
          >
            <vs-td>
              {{user.username}}
            </vs-td>
            <vs-td>
              <feather-icon
                :icon="removeUserIcon(user)"
                :svgClasses="`h-5 w-5 mb-1 mr-3 stroke-current ${removeUserIconStyle(user)}`"
                @click="removeUser(user)"
              />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

    <div class="col-span-12 flex gap-4 items-center justify-end">
      <vs-button type="border" @click="cancel">Cancelar</vs-button>
      <vs-button :disabled="invalidForm" @click="store">{{storeButtonText}}</vs-button>
    </div>
  </div>
</template>

<script>
import StudentService from '@/services/api/StudentService'
import FirebaseChatService from '@/services/firebase/FirebaseChatService'
import UserSuggestion from '@/components/UserSuggestion.vue'
import User from './firestore/models/User'
import { RoomType } from './firestore/models/ChatRoom'
import FeatherIcon from '@/components/FeatherIcon.vue'
import FirebaseService from '@/services/api/FirebaseService'

export default {
  components: { 
    UserSuggestion,
    FeatherIcon
  },

  props: {
    preserveModel: {
      default: false,
      type: Boolean
    },
    chatroomId: {
      default: null,
      type: String
    },
    firebaseChatService: {
      default: null,
      type: FirebaseChatService
    }
  },

  data:() => ({
    chatroomData: {
      roomName: '',
      description: '',
      users: [],
      type: {
        name: 'group',
        value: 'private'
      }
    },
    firebaseService: null,
    studentService: null
  }),

  computed: {
    invalidForm() {
      return this.isEmpty(this.chatroomData.roomName)
    },
    storeButtonText() {
      return this.chatroomId ? 'Salvar' : 'Criar'
    },
    currentUserId() {
      return this.$store.getters.userId
    }
  },

  methods: {
    async fillForm() {
      // If a chatroom is going to be created, push current user to users.
      if (this.chatroomId === null && this.chatroomData.users.length === 0) {
        const activeUser = this.$store.getters.AppActiveUser
        const user = User.fromDBUser(activeUser)
        user.flags.add('current_user')
        this.chatroomData.users.push(user)
      }
      if (this.chatroomId && this.firebaseChatService) {
        this.$vs.loading()
        await this.firebaseChatService.fetchRoom(this.chatroomId).then(room => {
          if (room) {
            this.chatroomData.roomName = room.roomName
            this.chatroomData.description = room.description
            this.chatroomData.users = room.users.map(user => {
              if (user.id.toString() === this.currentUserId.toString()) {
                user.flags.add('current_user')
              }
              return user
            })
          }
          this.$vs.loading.close()
        })
      }
    },

    resetForm() {
      this.chatroomId = null
      const userSuggestion = this.$refs['user-suggestion']
      if (userSuggestion) {
        userSuggestion.reset()
      }
      this.chatroomData = {
        roomName: '',
        description: '',
        users: [],
        type: {
          name: 'group',
          value: 'private'
        }
      }
    },

    addUser(data) {
      if (this.chatroomData.users.find(user => user.id === data.id.toString())) return
      const user = new User(data.id.toString(), data.name, data.profile_picture)
      user.flags.add('to_add')
      this.chatroomData.users.push(user)
    },

    removeUser(removeUser) {
      const whereToRemove = this.chatroomData.users.findIndex(user => user.id === removeUser.id)
      if (whereToRemove !== -1) {
        if (removeUser.flags.has('current_user')) return

        if (removeUser.flags.hasAny(['to_remove', 'current_user'])) {
          this.chatroomData.users[whereToRemove].flags.remove('to_remove')
          return
        }

        if (removeUser.flags.has('to_add')) {
          this.chatroomData.users.splice(whereToRemove, 1)
          return
        }

        this.chatroomData.users[whereToRemove].flags.add('to_remove')

      }

    },

    removeUserIcon(user) {
      return user.flags.has('to_remove') ? 'UserPlusIcon' : 'Trash2Icon'
    },

    userRowState(user, onCurrent = '', onRemove = '', onAdd = '', onNothing = '') {
      return (
        (user.flags.has('current_user') && onCurrent) ||
        (user.flags.has('to_remove') && onRemove) ||
        (user.flags.has('to_add') && onAdd) ||
        onNothing
      )
    },

    removeUserIconStyle(user) {
      return this.userRowState(user,
        'text-faint-grey',
        'text-success cursor-pointer',
        'text-danger cursor-pointer',
        'text-danger cursor-pointer'
      )
    },

    userRowColor(user) {
      return this.userRowState(user, 'dark', 'danger', 'success')
    },

    store() {
      if (this.firebaseChatService) {
        this.$vs.loading()
        this.firebaseChatService.storeRoom(this.chatroomData, this.chatroomId).then(() => {
          this.$vs.loading.close()
          this.emitAlterationEvent('confirm')
        }, error => {
          this.$vs.loading.close()
          this.notifyError(
            this.$vs,
            'Não foi possível criar este grupo'
          )
        })
      }
    },

    cancel() {
      this.emitAlterationEvent('cancel')
    },

    emitAlterationEvent(action) {
      const operation = this.chatroomId ? 'update' : 'create'
      this.$emit(`${action}-alteration`, {type: operation, data: this.chatroomData, id: this.chatroomId})
      if (!this.preserveModel) {
        this.resetForm()
      }
    }
  },

  mounted() {
    this.firebaseService = FirebaseService.build(this)
  }
}
</script>