import Rest from '../Rest'

export default class FirebaseService extends Rest {
  
  /**
   * @type {String}
   */
  static resource = '/api/v1/firebase'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  refreshToken() {
    return this.get('/token').then(data => {
      return data
    })
  }
}
