<template>
  <div class="flex flex-row gap-2">
    <div class="fixAutoComplete flex-grow">
      <select-suggestion
        column="name,email"
        model="User"
        v-model="selectedUser"
        :label="$t('usuario')"
        :appendClearOption="true"
        :itemTextFormat="
          (model) => `${model.name} <br/><div class=\'text-sm\'>${model.email}<div>`
        "
        placeholderText="Digite o nome ou e-mail do usuário"
      />
    </div>
    <div class="flex-grow-0 self-end">
      <vs-button
        type="filled"
        class="footer-button"
        @click="emitSelection"
        :disabled="!selectedUser"
      >
        {{ $t('adicionar') }}
      </vs-button>
    </div>
  </div>
</template>

<script>
import SelectSuggestion from './SelectSuggestion.vue'

export default {
  components: { SelectSuggestion },

  data: () => ({
    selectedUser: null,
  }),

  methods: {
    emitSelection() {
      this.$emit('selected-user', this.selectedUser)
    },
    reset() {
      this.selectedUser = null
    }
  },


}
</script>